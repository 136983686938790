@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

/* You can add global styles to this file, and also import other style files */

.mat-datepicker-panel,
.ngx-material-timepicker-overlay {
    z-index: 9999;
}

html,
body {
    height: 100%;
    background-color: rgb(245, 245, 245);
    margin: 0;
    padding: 0;
}